.title{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 999;
  font-size: 4rem;
}
.canvas{
  height: 100vh;
}
.relative{
  position: relative;
}
.audio{
  display: none;
}